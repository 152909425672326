import {
  DateFormat,
  DateUtils,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import * as ACData from "adaptivecards-templating";
import lodash from "lodash";
import moment from "moment";
import Trip from "../../../common/models/Trip";
import TripProduct from "../../../common/models/TripProduct";
import TripHelperV1 from "../../../helpers/TripHelperV1";
import i18next from "../../../i18n/i18n";
import { CP_FLOW_IDENTIFIER } from "../../../utils/constants";
import HostSettings from "../../../utils/host.settings";
import { getAdaptiveCardImageUrl } from "../../share-from-classic/functions/ShareHelper";
import { ITripV1 } from "../../upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import shareJoinCard from "../share-card/ShareJoinCard";
import shareJoinCardV1 from "../share-card/ShareJoinCardV1";
import { DeeplinkOptions } from "./ac-utils/DeeplinkOptions";
import cardActions from "./ac-utils/cardActions";
import channelsCardFooter from "./ac-utils/channelCardFooter";
import createFormattedDates from "./ac-utils/createFormattedDates";

const { t } = i18next;

const flightIcon = `${HostSettings.getCDNIcons}plane-white.png`;
const coffeeIcon = `${HostSettings.getCDNIcons}drink.png`;
const personIcon = `${HostSettings.getCDNIcons}person.png`;
const railIcon = `${HostSettings.getCDNIcons}rail.png`;

function setAdditionalInfo(trip: Trip) {
  const mytrip = lodash.cloneDeep(trip);
  if (mytrip.products) {
    mytrip.products[0].products.forEach((element: TripProduct) => {
      if (element.Trip_Product_Hotel) {
        const startDate = moment(element.Trip_Product_Hotel.checkInDate);
        const endDate = moment(element.Trip_Product_Hotel.checkOutDate);
        const numberOfNights = endDate.diff(startDate, "days");
        const nights =
          numberOfNights > 1
            ? t("shareTrip.adaptiveCard.hotelNights")
            : t("shareTrip.adaptiveCard.hotelOneNight");
        const hotelNights = `${numberOfNights} ${nights}`;
        const breakfastInfo =
          element.Trip_Product_Hotel.amenities &&
          element.Trip_Product_Hotel.amenities[0]?.includes("not")
            ? t("shareTrip.breakfastNotIncluded")
            : t("shareTrip.breakfastIncluded");

        // eslint-disable-next-line no-param-reassign
        element.Trip_Product_Hotel = {
          ...element.Trip_Product_Hotel,
          hotelNights,
          breakfastInfo,
        };
      }
    });
  }
  return mytrip;
}

function isOneWay(trip: any, origin: string): boolean {
  let endDate: string;
  let startDate: string;

  if (origin === CP_FLOW_IDENTIFIER) {
    endDate = trip.end.localDateTime.split("T")[0];
    startDate = trip.start.localDateTime.split("T")[0];
  } else {
    endDate = trip.endDate?.split("T")[0];
    startDate = trip.startDate?.split("T")[0];
  }

  return endDate === startDate;
}

function setCustomTitle(trip: any, clientType: string) {
  // we can cast it to V1 as in CP flow we expect V1
  const tripAsTrivpV1 = trip as ITripV1;
  const tripTitle = TripHelperV1.getTripTitleForCPFlow(
    tripAsTrivpV1,
    clientType
  );
  return { ...trip, customTitle: tripTitle };
}

function localizeDates(origin: string, trip: any, language: string) {
  if (origin !== CP_FLOW_IDENTIFIER) {
    const formattedTrip = lodash.cloneDeep(trip);
    const startDateFormatted = trip.startDate
      ? DateUtils.getDateFormat(trip.startDate, language, DateFormat.DATE_SHORT)
      : trip.startDate;
    const endDateFormatted = trip.endDate
      ? DateUtils.getDateFormat(trip.endDate, language, DateFormat.DATE_SHORT)
      : trip.endDate;
    formattedTrip.startDate = startDateFormatted;
    formattedTrip.endDate = endDateFormatted;

    return createFormattedDates(formattedTrip, language);
  }
  return trip;
}

export function GetCardToShare(
  incomingTrip: Trip,
  appCatalogId: string,
  recipientChannelsSelected: string[] | undefined,
  origin: string,
  language: string,
  clientType: string,
  deeplinkOptions: DeeplinkOptions
) {
  const meta = {
    flightIcon,
    coffeeIcon,
    personIcon,
    railIcon,
    translations: {
      pickUp: t("shareTrip.adaptiveCard.pickUp"),
      dropOff: t("shareTrip.adaptiveCard.dropOff"),
      title: t("shareTrip.adaptiveCard.title"),
      subtitle:
        origin === CP_FLOW_IDENTIFIER
          ? t("shareTrip.adaptiveCard.subtitleSharingForOtherUser")
          : t("shareTrip.adaptiveCard.subtitle"),
      footer: t("shareTrip.adaptiveCard.bookingAvailability"),
    },
  };
  const adaptiveCards = {
    general: "",
    forChannels: "",
  };
  let trip =
    origin === CP_FLOW_IDENTIFIER
      ? setCustomTitle(incomingTrip, clientType)
      : setAdditionalInfo(incomingTrip);
  const oneWayFlight: boolean = isOneWay(trip, origin);
  const mediaHref = getAdaptiveCardImageUrl(trip.media?.[1]?.href);
  trip = localizeDates(origin, trip, language);

  trip = {
    ...trip,
    oneWayFlight,
    mediaHref,
    purpose: trip.purposeDescription || trip.purpose,
  };
  const adaptiveCardTemplate = new ACData.Template(
    origin === CP_FLOW_IDENTIFIER ? shareJoinCardV1 : shareJoinCard
  );
  const adaptiveContext: ACData.IEvaluationContext = {
    $root: {
      trip,
      meta,
    },
  };
  const cardPayload = adaptiveCardTemplate.expand(adaptiveContext);
  if (trip) {
    cardPayload?.body.push(cardActions(trip, appCatalogId, deeplinkOptions));
    adaptiveCards.general = JSON.stringify(cardPayload);

    if (
      recipientChannelsSelected &&
      recipientChannelsSelected.length &&
      origin !== "events"
    ) {
      cardPayload?.body.push(channelsCardFooter());
      adaptiveCards.forChannels = JSON.stringify(cardPayload);
    }
  }

  return adaptiveCards;
}

export default GetCardToShare;
